import React, { useEffect, useState } from 'react';
import DashboardStatsGrid from '../components/DashboardStatsGrid';
import TransactionChart from '../components/TransactionChart';
import RecentOrders from '../components/RecentOrders';
import BuyerProfilePieChart from '../components/BuyerProfilePieChart';
import PopularProducts from '../components/PopularProducts';
import Loader from './shared/Loader';
import { BASEURL } from './shared/Config';

export default function Dashboard() {
    const [userData, setUserData] = useState()
    const [loading, setLoading] = useState(false)
    useEffect(() => {
        setLoading(true)
        fetchData()
    }, [])

    const fetchData = async (page = 1) => {
        try {
            const response = await fetch(`${BASEURL}live/status`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer " + localStorage.getItem("token")
                },
            });
            const data = await response.json();
            setUserData(data)
            setLoading(false)
            console.log('datadatadata', data)


        } catch (error) {
            console.error('Error fetching sales executives:', error);
        }
    };

    return (
        <div className="flex flex-col gap-4">
            {
                loading &&

                <Loader />
            }
            <DashboardStatsGrid userData={userData} />
            <div className="flex flex-row gap-4 w-full">
                <TransactionChart userData={userData} />
                <BuyerProfilePieChart userData={userData} />
            </div>
            <div className="flex flex-row gap-4 w-full">
                <RecentOrders userData={userData} />
                <PopularProducts userData={userData} />
            </div>
        </div>
    );
}
