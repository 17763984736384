import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ThemeContext } from './ThemeContext';
import { BASEURL } from './shared/Config';


export default function RecentOrders() {
	const navigate = useNavigate();
	const [ordersList, setOrdersList] = useState([])
	const { mode } = useContext(ThemeContext);
	const fetchOrders = async (page = 1) => {
		try {
			const response = await fetch(`${BASEURL}orders?page=${1}&limit=${5}&sortBy=orderDate:desc`, {
				method: 'GET',
				headers: {
					'Authorization': "Bearer " + localStorage.getItem("token")
				},
			});
			const data = await response.json();
			setOrdersList(data.orders)
		} catch (error) {
			console.error('Error fetching orders:', error);
		} finally {
		}
	};

	useEffect(() => {
		fetchOrders();
	}, []);
	return (
		<div className="bg-white px-4 pt-3 pb-4 rounded-sm border border-gray-200 flex-1">
			<strong className="text-gray-700 font-medium">Recent Orders</strong>
			<div className="border-x border-gray-200 rounded-sm mt-3">
				<table className="w-full text-gray-700">
					<thead>
						<tr>
							<th className="text-gray-800 border border-gray-300 px-2 py-2">Order ID</th>
							<th className="text-gray-800 border border-gray-300 px-2 py-2">Item Name</th>
							<th className="text-gray-800 border border-gray-300 px-2 py-2">Quantity</th>
							<th className="text-gray-800 border border-gray-300 px-2 py-2">Date Added</th>
							<th className="text-gray-800 border border-gray-300 px-2 py-2">Cost</th>
							<th className="text-gray-800 border border-gray-300 px-2 py-2">Shop Name</th>
						</tr>
					</thead>
					<tbody>
						{ordersList?.map((order) => (
							<tr key={order._id}>
								<td className="text-gray-800 border border-gray-300 px-4 py-2">{order._id}</td>
								<td className={`text-gray-800 border border-gray-300 px-4 py-2 cursor-pointer ${mode === 'Dark' ? 'text-white hover:text-blue-300' : 'text-black-500 hover:text-blue-700'} transition duration-300`} onClick={() => {
									navigate(`/order-tracking/${order._id}`, { state: { order } });
								}}>
									{order.items[0].inventory.item.name}
								</td>

								<td className="text-gray-800 border border-gray-300 px-4 py-2">{order.items[0].quantity}</td>
								<td className="text-gray-800 border border-gray-300 px-4 py-2">
									{new Date(order.orderDate).toLocaleDateString('en-GB')}
								</td>
								<td className="text-gray-800 border border-gray-300 px-4 py-2">{order.totalAmount}</td>
								<td className="text-gray-800 border border-gray-300 px-4 py-2">{order.shop.name}</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
		</div>
	)
}
