import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import Loader from './shared/Loader';
import { BASEURL } from './shared/Config';

const ManagerProfile = () => {
  const { id } = useParams();
  const location = useLocation();
  const { data } = location.state || {};
  const [loading, setLoading] = useState(false);
  const [salesExecutiveList, setSalesExecutiveList] = useState([]);
  const [deliveryAgentList, setDeliveryAgentList] = useState([]);
  const [walletAmount, setWalletAmount] = useState(0); // State for wallet amount

  useEffect(() => {
    setLoading(true);
    FetchMoney();
    fetchExecutives();
  }, []);

  const fetchExecutives = async (page = 1) => {
    try {
      const response = await fetch(`${BASEURL}sales-executive?page=${page}&limit=${50}&assignedBy=${id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': "Bearer " + localStorage.getItem("token"),
        },
      });
      const data = await response.json();
      setSalesExecutiveList(data?.users || []);
      
      const seenIds = new Set();
      const uniqueDeliveryPersons = (data?.users || [])
        .flatMap(user => user.deliveryPersons || [])
        .filter(person => {
          if (seenIds.has(person._id)) {
            return false;
          }
          seenIds.add(person._id);
          return true;
        });
      
      setDeliveryAgentList(uniqueDeliveryPersons);
      setLoading(false);

    } catch (error) {
      console.error('Error fetching sales executives:', error);
      setLoading(false);
    }
  };

  const FetchMoney = async () => {
    try {
      const response = await fetch(`${BASEURL}user/${id}/active-money`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': "Bearer " + localStorage.getItem("token"),
        },
      });
      const data = await response.json();
      setWalletAmount(data?.activeMoney);
      setLoading(false);

    } catch (error) {
      console.error('Error FetchMoney ', error);
      setLoading(false);
    }
  };

  const handleCollectMoney = async () => {
    try {
      const response = await fetch(`${BASEURL}user/${id}/collect-manager-money`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': "Bearer " + localStorage.getItem("token"),
        },
      });
      const data = await response.json();
      console.log('Money collected:', data);
      FetchMoney()
      alert('Money Collect Successfully')
      setLoading(false);

    } catch (error) {
      alert(error?.message);
      console.error('Error handleCollectMoney', error);
      setLoading(false);
    }
  };

  return (
    <div className="flex flex-col gap-6 p-4 bg-gray-100 min-h-screen">
      {loading && <Loader />}
      <div className="w-full flex flex-col md:flex-row items-center bg-white p-6 rounded-lg shadow-lg">
        <img
          src={data?.photo ? data.photo : require('../components/shared/img/dummyProfile.png')}
          alt="Profile"
          className="w-16 h-16 object-cover rounded-full"
        />
        <div className="md:ml-4 flex-1 flex flex-col justify-center">
          <h1 className="text-2xl font-bold text-black">{data?.name}'s Profile</h1>
          <div className="mt-4 flex flex-col md:flex-row items-center md:items-start">
            <p className="text-lg font-semibold text-black pt-1.5">Wallet Amount: {walletAmount}₹</p>
            {
               !loading&& walletAmount !=0 &&
            <button
              onClick={handleCollectMoney}
              className="mt-4 md:mt-0 md:ml-4 px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition duration-200"
            >
              Collect Money
            </button>
            }
          </div>
        </div>
        <div className="text-black mt-4 md:mt-0">
          <p><strong>User ID:</strong> {data?._id}</p>
          <p><strong>Email:</strong> {data?.email}</p>
          <p><strong>Phone:</strong> {data?.mobile}</p>
          <p><strong>Area:</strong> {data?.region}</p>
          <p><strong>Address:</strong> {data?.address}</p>
        </div>
      </div>

      <div className="w-full bg-white p-6 rounded-lg shadow-lg">
        <h2 className="text-xl font-bold text-black mb-4">Sales Executive List</h2>
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-black uppercase tracking-wider">Name</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-black uppercase tracking-wider">Email</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-black uppercase tracking-wider">Mobile</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-black uppercase tracking-wider">Status</th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {salesExecutiveList.length > 0 ? (
                salesExecutiveList.map(executive => (
                  <tr key={executive._id}>
                    <td className="px-6 py-4 whitespace-nowrap text-black">{executive.name}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-black">{executive.email}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-black">{executive.mobile}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-green-500">{executive.status}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="4" className="px-6 py-4 text-center text-black">No Sales Executives Available</td>
                </tr>
              )}
            </tbody>
          </table>
          <h2 className="text-xl font-bold text-black mb-4 mt-10">Delivery Boy List</h2>
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-black uppercase tracking-wider">Name</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-black uppercase tracking-wider">Email</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-black uppercase tracking-wider">Mobile</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-black uppercase tracking-wider">Status</th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {deliveryAgentList.length > 0 ? (
                deliveryAgentList.map(boy => (
                  <tr key={boy._id}>
                    <td className="px-6 py-4 whitespace-nowrap text-black">{boy.name}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-black">{boy.email}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-black">{boy.mobile}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-green-500">{boy.status}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="4" className="px-6 py-4 text-center text-black">No Delivery Boys Available</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default ManagerProfile;
