import React, { useState, Fragment, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faEdit } from '@fortawesome/free-solid-svg-icons';
import Loader from './shared/Loader';
import { BASEURL } from './shared/Config';

const initialItemState = {
  name: '',
  description: '',
  unit: '',
  category: '',
};

export default function ItemTable({ searchQuery }) {
  const [isOpen, setIsOpen] = useState(false);
  const [newItem, setNewItem] = useState(initialItemState);
  const [editingItemId, setEditingItemId] = useState(null);
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const openModal = (item = initialItemState) => {
    setIsOpen(true);
    setNewItem({name:item?.name,description:item?.description,unit:item?.unit,category:item?.category});
    setEditingItemId(item?._id || null);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const fetchItems = async (page = 1) => {
    try {
      setLoading(true);
      const response = await fetch(`${BASEURL}items?page=${page}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
      });
      const data = await response.json();

      if (!data.error) {
        setItems(data.items);
        setCurrentPage(data.currentPage);
        setTotalPages(data.totalPages);
        setTotalItems(data.totalItems);
      } else {
        console.log(data.error);
      }
    } catch (error) {
      console.error('Error fetching items:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchItems();
  }, []);

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      fetchItems(newPage);
    }
  };

  let filteredItems = items;
  if (searchQuery.length > 2) {
    filteredItems = items.filter(item =>
      item.name.toLowerCase().includes(searchQuery.toLowerCase())
    );
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewItem({ ...newItem, [name]: value });
  };

  const handleAddOrUpdateItem = async () => {
    const isFormComplete = Object.values(newItem).every(value => String(value).trim() !== '');
    if (isFormComplete) {
      if (editingItemId) {
        try {
          const response = await fetch(`${BASEURL}items/${editingItemId}`, {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${localStorage.getItem('token')}`,
            },
            body: JSON.stringify(newItem),
          });
          const updatedItem = await response.json();
          if(response.status == 200){
          console.log('response',updatedItem)
          setItems(prevItems =>
            prevItems?.map(item =>
              item?._id === editingItemId ? newItem : item
            )
          );
        }else{
          console.log('response',response)
          alert(updatedItem.message)
        }
        } catch (error) {
          console.error('Error updating item:', error);
        }
      } else {
        try {
          const response = await fetch(`${BASEURL}items`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${localStorage.getItem('token')}`,
            },
            body: JSON.stringify(newItem),
          });
          const result = await response.json();
          if (!result.error) {
            setItems([...items, result.item]);
          } else {
            console.log(result.error);
          }
        } catch (error) {
          console.error('Error adding item:', error);
        }
      }
      closeModal();
    } else {
      alert('Please fill out all fields.');
    }
  };

  const handleDeleteItem = async (id) => {
    try {
      const response = await fetch(`${BASEURL}items/${id}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
      });
      const data = await response.json();
      if(response.status == 200){

        console.log('datadata',response)
        setItems(prevItems => prevItems.filter(item => item?._id !== id));
      }else if(response.status == 400){
        alert(data.message)
      }
      
    } catch (error) {
      console.error('Error deleting item:', error);
    }
    setIsDeleteConfirmationOpen(false)
  };

  const openDeleteConfirmation = (id) => {
    setItemToDelete(id);
    setIsDeleteConfirmationOpen(true);
  };

  // Use this function when clicking the delete button
  const handleDeleteButtonClick = (id) => {
    openDeleteConfirmation(id);
  };

  return (
    <div className="flex flex-col lg:flex-row gap-6 ">
      {loading && <Loader />}
      <div className="w-full bg-white p-6 rounded-lg shadow-lg">
        <div className="flex justify-between items-center mb-4">
          <h1 className="text-gray-900 text-2xl font-bold">Item List</h1>
          <button
            className="bg-green-600 text-white py-2 px-4 rounded hover:text-white hover:bg-green-700 transition duration-100"
            onClick={() => openModal()}
          >
            Add Item
          </button>
        </div>
        <table className="min-w-full border border-gray-300">
          <thead className="bg-gray-50">
            <tr>
              <th className="text-gray-800 border border-gray-300 px-2 py-2">Item ID</th>
              <th className="text-gray-800 border border-gray-300 px-2 py-2">Item Name</th>
              <th className="text-gray-800 border border-gray-300 px-2 py-2">Description</th>
              <th className="text-gray-800 border border-gray-300 px-2 py-2">Unit</th>
              <th className="text-gray-800 border border-gray-300 px-2 py-2">Category</th>
              <th className="text-gray-800 border border-gray-300 px-2 py-2">Actions</th>
            </tr>
          </thead>
          <tbody className="bg-white">
            {filteredItems?.map((item) => (
              <tr key={item?._id}>
                <td className="text-gray-800 border border-gray-300 px-4 py-2">{item?._id}</td>
                <td className="text-gray-800 border border-gray-300 px-4 py-2">{item?.name}</td>
                <td className="text-gray-800 border border-gray-300 px-4 py-2">{item?.description}</td>
                <td className="text-gray-800 border border-gray-300 px-4 py-2">{item?.unit}</td>
                <td className="text-gray-800 border border-gray-300 px-4 py-2">{item?.category}</td>
                <td className="text-gray-800 border border-gray-300 px-4 py-2 flex space-x-2">
                  <button
                    className="text-blue-500 hover:text-blue-700 transition duration-300"
                    onClick={() => openModal(item)}
                  >
                    <FontAwesomeIcon icon={faEdit} />
                  </button>
                  <button
                    className="text-red-500 hover:text-red-700 transition duration-300"
                    onClick={() => handleDeleteButtonClick(item?._id)}
                  >
                    <FontAwesomeIcon icon={faTrash} />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="flex justify-between items-center mt-4">
          <button
            className="bg-gray-300 text-gray-800 py-2 px-4 rounded disabled:opacity-50"
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
          >
            Previous
          </button>
          <span className="text-black">
            Page {currentPage} of {totalPages == 0?1:totalPages}
          </span>
          <button
            className="bg-gray-300 text-gray-800 py-2 px-4 rounded disabled:opacity-50"
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
          >
            Next
          </button>
        </div>
      </div>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-full p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-4xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900 text-center">
                    {editingItemId ? 'Update Item' : 'Add Item'}
                  </Dialog.Title>
                  <div className="grid grid-cols-1 gap-4 mt-4">
                    <div className="grid grid-cols-2 gap-4">
                      <div>
                        <label className="block mb-2">Item Name</label>
                        <input
                          type="text"
                          name="name"
                          value={newItem.name}
                          onChange={handleInputChange}
                          className="w-full px-2 py-1 border rounded"
                        />
                      </div>
                      <div>
                        <label className="block mb-2">Description</label>
                        <input
                          type="text"
                          name="description"
                          value={newItem.description}
                          onChange={handleInputChange}
                          className="w-full px-2 py-1 border rounded"
                        />
                      </div>
                      <div>
                        <label className="block mb-2">Unit</label>
                        <select
                          name="unit"
                          value={newItem.unit}
                          onChange={handleInputChange}
                          className="w-full px-2 py-1 border rounded"
                        >
                          <option value="">Select Unit</option>
                          <option value="l">l</option>
                          <option value="ml">ml</option>
                          <option value="kg">kg</option>
                          <option value="gm">gm</option>
                          <option value="unit">unit</option>
                        </select>
                      </div>
                      <div>
                        <label className="block mb-2">Category</label>
                        <input
                          type="text"
                          name="category"
                          value={newItem.category}
                          onChange={handleInputChange}
                          className="w-full px-2 py-1 border rounded"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="mt-4 flex justify-end gap-4">
                    <button
                      className="bg-gray-500 text-white py-2 px-4 rounded"
                      onClick={closeModal}
                    >
                      Cancel
                    </button>
                    <button
                      className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-700 transition duration-300"
                      onClick={handleAddOrUpdateItem}
                    >
                      {editingItemId ? 'Update Item' : 'Add Item'}
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
      <Transition appear show={isDeleteConfirmationOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={() => setIsDeleteConfirmationOpen(false)}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-full p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title as="h2" className="text-lg mb-4 font-bold leading-6 text-gray-900 text-center">
                    Confirm Delete
                  </Dialog.Title>
                  <div className="mt-2">
                    <p style={{textAlign:'center',marginBottom:20}}>Are you sure you want to delete this Item?</p>
                  </div>
                  <div className="mt-4 flex justify-end">

                    <button
                      type="button"
                      className="bg-gray-300 text-gray-700 px-4 py-2 rounded hover:bg-gray-400 ml-2"
                      onClick={() => setIsDeleteConfirmationOpen(false)}
                    >
                      Cancel
                    </button>

                    <button
                      type="button"
                      className="bg-red-600 text-white px-4 py-2 ml-4 rounded hover:bg-red-700"
                      onClick={() => handleDeleteItem(itemToDelete)}
                    >
                      Delete
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
}
