import React, { useEffect, useState } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { BASEURL } from './shared/Config';

export default function TransactionChart() {
  const [transData, setTransData] = useState([]);
  const [month, setMonth] = useState(new Date().getMonth() + 1); // Current month
  const [year, setYear] = useState(new Date().getFullYear()); // Current year

  useEffect(() => {
    fetchData();
  }, [month, year]); // Re-fetch data when month or year changes

  const fetchData = async () => {
    try {
      const response = await fetch(`${BASEURL}live/order/status/month?month=${month}&year=${year}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': "Bearer " + localStorage.getItem("token")
        },
      });

      if (!response.ok) {
        throw new Error('Error fetching data');
      }

      const data = await response.json();
      console.log('Fetched data:', data);

      // Format the data for the chart
      const formattedData = data?.map(item => ({
        day: new Date(item.date).getDate().toString().padStart(2, '0'),
        completedOrders: item.completedOrders,
        totalOrders: item.totalOrders
      }));

      setTransData(formattedData);
    } catch (error) {
      console.error('Error fetching sales data:', error);
    }
  };

  const handleMonthChange = (e) => {
    setMonth(Number(e.target.value));
  };

  const handleYearChange = (e) => {
    setYear(Number(e.target.value));
  };

  // Fixed year options for the last 5 years
  const yearOptions = [2024, 2023, 2022, 2021, 2020];

  return (
    <div className="h-[22rem] bg-white p-4 rounded-sm border border-gray-200 flex flex-col flex-1">
      <strong className="text-gray-700 font-medium">Order Statistics</strong>
      <div className="mt-3 flex items-center space-x-4">
        <select style={{color:'black'}} value={month} onChange={handleMonthChange} className="border p-1 rounded ">
          {[...Array(12)].map((_, i) => (
            <option key={i} value={i + 1}>{new Date(0, i).toLocaleString('default', { month: 'long' })}</option>
          ))}
        </select>
        <select style={{color:'black'}} value={year} onChange={handleYearChange} className="border p-1 rounded">
          {yearOptions.map(y => (
            <option key={y} value={y}>{y}</option>
          ))}
        </select>
      </div>
      <div className="mt-3 w-full flex-1 text-xs">
        <ResponsiveContainer width="100%" height="100%">
          <LineChart
            width={500}
            height={300}
            data={transData}
            margin={{
              top: 20,
              right: 10,
              left: -10,
              bottom: 0
            }}
          >
            <CartesianGrid strokeDasharray="3 3" vertical={false} />
            <XAxis dataKey="day" />
            <YAxis 
              ticks={[10, 20, 30, 40, 50]}  // Fixed Y-axis tick values
              domain={[0, 50]}  // Fixed range for Y-axis
              tickFormatter={(value) => `${value}`} // Corrected template literal for Y-axis values
            />
            <Tooltip />
            <Legend />
            <Line type="monotone" dataKey="completedOrders" stroke="#0eb0ad" />
            <Line type="monotone" dataKey="totalOrders" stroke="#ed7c02" />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
}
