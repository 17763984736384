import React, { Fragment, useContext } from 'react';
import { Menu, Popover, Transition } from '@headlessui/react';
import { HiOutlineBell, HiOutlineSearch, HiOutlineChatAlt } from 'react-icons/hi';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { ThemeContext } from '../ThemeContext';

export default function Header({ searchQuery, setSearchQuery }) {
  const navigate = useNavigate();
  const { mode, toggleMode } = useContext(ThemeContext);

  const handleLogout = () => {
    // Clear the token and expiration time from local storage
    localStorage.removeItem('token');
    localStorage.removeItem('tokenExpiration');
    
    // Optionally, you could also clear other user-related data

    // Notify the app of logout (if needed) and redirect to login page
    navigate('/login');
  };
  return (
    <div className="bg-white dark:bg-gray-800 h-16 px-4 flex items-center border-b border-gray-200 dark:border-gray-700 justify-between">
      <div className="relative">
        <HiOutlineSearch fontSize={20} className="text-gray-400 dark:text-gray-300 absolute top-1/2 left-3 -translate-y-1/2" />
        <input
          type="text"
          placeholder="Search..."
          value={searchQuery}
          className="text-sm focus:outline-none active:outline-none border border-gray-300 dark:border-gray-600 w-[24rem] h-10 pl-11 pr-4 rounded-sm bg-white dark:bg-gray-700 dark:text-white"
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </div>
      <div className="flex items-center gap-2 mr-2">
      <Popover className="relative">
        {({ open }) => (
          <>
            <Popover.Button
              className={classNames(
                open && 'bg-gray-100 dark:bg-gray-700',
                'group inline-flex items-center rounded-sm p-1.5 text-gray-700 dark:text-gray-300 hover:text-opacity-100 focus:outline-none active:bg-gray-100 dark:active:bg-gray-700'
              )}
            >
              {/* <HiOutlineChatAlt fontSize={24} /> */}
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute right-0 z-10 mt-2.5 transform w-80">
                <div className="bg-white dark:bg-gray-800 rounded-sm shadow-md ring-1 ring-black ring-opacity-5 dark:ring-opacity-0 px-2 py-2.5">
                  <strong className="text-gray-700 dark:text-white font-medium">Messages</strong>
                  <div className="mt-2 py-1 text-sm dark:text-gray-300">This is messages panel.</div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
      <Popover className="relative">
        {({ open }) => (
          <>
            <Popover.Button
              className={classNames(
                open && 'bg-gray-100 dark:bg-gray-700',
                'group inline-flex items-center rounded-sm p-1.5 text-gray-700 dark:text-gray-300 hover:text-opacity-100 focus:outline-none active:bg-gray-100 dark:active:bg-gray-700'
              )}
            >
              {/* <HiOutlineBell fontSize={24} /> */}
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute right-0 z-10 mt-2.5 transform w-80">
                <div className="bg-white dark:bg-gray-800 rounded-sm shadow-md ring-1 ring-black ring-opacity-5 dark:ring-opacity-0 px-2 py-2.5">
                  <strong className="text-gray-700 dark:text-white font-medium">Notifications</strong>
                  <div className="mt-2 py-1 text-sm dark:text-gray-300">This is notification panel.</div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
      <Menu as="div" className="relative">
        <div>
          <Menu.Button className="ml-2 flex items-center justify-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-neutral-400">
            <span className="sr-only">Open user menu</span>
            <div
              className="h-10 w-10 rounded-full bg-cover bg-center"
              style={{ backgroundImage:`url(${require('../../components/shared/img/logo.png')})` }}
            >
              {/* Image for user */}
            </div>
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="origin-top-right z-10 absolute right-0 mt-2 w-48 rounded-sm shadow-md p-1 bg-white dark:bg-gray-800 ring-1 ring-black ring-opacity-5 dark:ring-opacity-0 focus:outline-none">
            {/* <Menu.Item>
              {({ active }) => (
                <div
                  onClick={() => navigate('/profile')}
                  className={classNames(
                    active && 'bg-gray-100 dark:bg-gray-700',
                    'active:bg-gray-200 dark:active:bg-gray-700 rounded-sm px-4 py-2 text-gray-700 dark:text-gray-300 cursor-pointer focus:bg-gray-200 dark:focus:bg-gray-700'
                  )}
                >
                  Your Profile
                </div>
              )}
            </Menu.Item> */}
            {/* <Menu.Item>
              {({ active }) => (
                <div
                  onClick={() => navigate('/settings')}
                  className={classNames(
                    active && 'bg-gray-100 dark:bg-gray-700',
                    'active:bg-gray-200 dark:active:bg-gray-700 rounded-sm px-4 py-2 text-gray-700 dark:text-gray-300 cursor-pointer focus:bg-gray-200 dark:focus:bg-gray-700'
                  )}
                >
                  Settings
                </div>
              )}
            </Menu.Item> */}
            <Menu.Item>
              {({ active }) => (
                <div
                  onClick={() => handleLogout()}
                  className={classNames(
                    active && 'bg-gray-100 dark:bg-gray-700',
                    'active:bg-gray-200 dark:active:bg-gray-700 rounded-sm px-4 py-2 text-gray-700 dark:text-gray-300 cursor-pointer focus:bg-gray-200 dark:focus:bg-gray-700'
                  )}
                >
                  Sign out
                </div>
              )}
            </Menu.Item>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
    </div>
  );
}
