import React from 'react';
import { FaMoon, FaSun, FaArrowRight } from 'react-icons/fa';
 

const PersonalDetails = () => {
  return (
    <div className="container mx-auto py-2">
      <h2 className="text-2xl font-bold mb-6">General Settings</h2>
      <div className="bg-white dark:bg-gray-800 shadow rounded-md">
        
       
        <div className="border-b border-gray-300 dark:border-gray-700 py-4 px-6 flex items-center justify-between">
          <span className="text-md">Languages</span>
          <FaArrowRight className="text-gray-500" />
        </div>
        <div className="border-b border-gray-300 dark:border-gray-700 py-4 px-6 flex items-center justify-between">
          <span className="text-md">Notification & Alert </span>
          <FaArrowRight className="text-gray-500" />
        </div>
        <div className="border-b border-gray-300 dark:border-gray-700 py-4 px-6 flex items-center justify-between">
          <span className="text-md">Backup & Restore</span>
          <FaArrowRight className="text-gray-500" />
        </div>
        <div className="py-4 px-6 flex items-center justify-between">
          <span className="text-md">Clear Data</span>
          <FaArrowRight className="text-gray-500" />
        </div>
      </div>
    </div>
  );
};

export default PersonalDetails;
