import React, { useContext } from 'react';
import { FaMoon, FaSun, FaArrowRight } from 'react-icons/fa';
import { ThemeContext } from './ThemeContext';
import { Link } from 'react-router-dom'; 

const Setting = () => {
  const { mode, toggleMode } = useContext(ThemeContext);

  return (
    <div className="container mx-auto py-2 dark:text-white">
      <h2 className="text-2xl font-bold mb-6">Settings</h2>
      <div className="bg-white dark:bg-gray-800 shadow rounded-md">
        {/* <div className="border-b border-gray-300 dark:border-gray-700 py-4 px-6 flex items-center justify-between">
          <span className="text-md">Mode</span>
          <div className="flex items-center cursor-pointer" onClick={toggleMode}>
            {mode === 'Light' ? (
              <FaSun className="text-yellow-500 mr-2" />
            ) : (
              <FaMoon className="text-gray-300 mr-2" />
            )}
            <span className="text-sm">{mode === 'Light' ? 'Light' : 'Dark'} Mode</span>
          </div>
        </div> */}
        {/* <Link to="/personal-details" className="text-black hover:no-underline hover:text-black">
  <div className="border-b  border-gray-300 dark:border-gray-700 py-4 px-6 flex items-center justify-between">
    <span className="text-md">Personal Details</span>
    <FaArrowRight className="text-gray-500" />
  </div>
</Link> */}
<Link to="/general-settings" className="text-black hover:no-underline hover:text-black">
  <div className="border-b border-gray-300 dark:border-gray-700 py-4 px-6 flex items-center justify-between">
    <span className="text-md">General Settings</span>
    <FaArrowRight className="text-gray-500" />
  </div>
</Link>

        <div className="border-b border-gray-300 dark:border-gray-700 py-4 px-6 flex items-center justify-between">
          <span className="text-md">Notifications and Alerts</span>
          <FaArrowRight className="text-gray-500" />
        </div>
        <div className="py-4 px-6 flex items-center justify-between">
          <span className="text-md">Privacy Policies</span>
          <FaArrowRight className="text-gray-500" />
        </div>
      </div>
    </div>
  );
};

export default Setting;
