import React, { useState } from 'react';
import { FaArrowRight } from 'react-icons/fa';

const PersonalDetails = () => {
  const [showModal, setShowModal] = useState({ username: false, password: false, email: false, phone: false });
  const [formData, setFormData] = useState({ currentUsername: '', newUsername: '', currentPassword: '', newPassword: '', confirmPassword: '', email: '', phone: '' });
  const [currentUsername, setCurrentUsername] = useState('currentUsername'); // Replace 'currentUsername' with the actual current username
  const [step, setStep] = useState({ username: 1, password: 1 });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleModal = (field, value) => {
    setShowModal({ ...showModal, [field]: value });
    setStep({ ...step, [field]: 1 });
  };

  const handleSubmit = (field) => {
    // Add your logic for updating the details
    console.log(`${field} updated to:`, formData[`new${capitalizeFirstLetter(field)}`]);
    if (field === 'username') setCurrentUsername(formData.newUsername);
    handleModal(field, false);
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  return (
    <div className="container mx-auto py-2">
      <h2 className="text-2xl font-bold mb-6">Personal Details</h2>
      <div className="bg-white dark:bg-gray-800 shadow rounded-md">
        <div className="border-b border-gray-300 dark:border-gray-700 py-4 px-6 flex items-center justify-between">
          <span className="text-md">User Name: {currentUsername}</span>
          <FaArrowRight className="text-gray-500 cursor-pointer" onClick={() => handleModal('username', true)} />
        </div>
        <div className="border-b border-gray-300 dark:border-gray-700 py-4 px-6 flex items-center justify-between">
          <span className="text-md">Password</span>
          <FaArrowRight className="text-gray-500 cursor-pointer" onClick={() => handleModal('password', true)} />
        </div>
        <div className="border-b border-gray-300 dark:border-gray-700 py-4 px-6 flex items-center justify-between">
          <span className="text-md">Email</span>
          <FaArrowRight className="text-gray-500 cursor-pointer" onClick={() => handleModal('email', true)} />
        </div>
        <div className="py-4 px-6 flex items-center justify-between">
          <span className="text-md">Mobile Number</span>
          <FaArrowRight className="text-gray-500 cursor-pointer" onClick={() => handleModal('phone', true)} />
        </div>
      </div>

      {showModal.username && (
        <Modal onClose={() => handleModal('username', false)}>
          {step.username === 1 ? (
            <>
              <h3 className="text-xl font-bold mb-4">Confirm Current Username</h3>
              <input
                type="text"
                name="currentUsername"
                value={formData.currentUsername}
                onChange={handleChange}
                placeholder="Current Username"
                className="input-field mb-4 p-2 border rounded w-full"
              />
              <div className="flex justify-end">
                <button
                  onClick={() => setStep({ ...step, username: 2 })}
                  className="bg-blue-500 text-white px-4 py-2 rounded"
                >
                  Next
                </button>
              </div>
            </>
          ) : (
            <>
              <h3 className="text-xl font-bold mb-4">Edit Username</h3>
              <input
                type="text"
                name="newUsername"
                value={formData.newUsername}
                onChange={handleChange}
                placeholder="New Username"
                className="input-field mb-4 p-2 border rounded w-full"
              />
              <div className="flex justify-end">
                <button onClick={() => handleSubmit('username')} className="bg-blue-500 text-white px-4 py-2 rounded mr-2">Save</button>
                <button onClick={() => handleModal('username', false)} className="bg-gray-500 text-white px-4 py-2 rounded">Cancel</button>
              </div>
            </>
          )}
        </Modal>
      )}

      {showModal.password && (
        <Modal onClose={() => handleModal('password', false)}>
          {step.password === 1 ? (
            <>
              <h3 className="text-xl font-bold mb-4">Confirm Current Password</h3>
              <input
                type="password"
                name="currentPassword"
                value={formData.currentPassword}
                onChange={handleChange}
                placeholder="Current Password"
                className="input-field mb-4 p-2 border rounded w-full"
              />
              <div className="flex justify-end">
                <button
                  onClick={() => setStep({ ...step, password: 2 })}
                  className="bg-blue-500 text-white px-4 py-2 rounded"
                >
                  Next
                </button>
              </div>
            </>
          ) : (
            <>
              <h3 className="text-xl font-bold mb-4">Edit Password</h3>
              <input
                type="password"
                name="newPassword"
                value={formData.newPassword}
                onChange={handleChange}
                placeholder="New Password"
                className="input-field mb-4 p-2 border rounded w-full"
              />
              <input
                type="password"
                name="confirmPassword"
                value={formData.confirmPassword}
                onChange={handleChange}
                placeholder="Confirm New Password"
                className="input-field mb-4 p-2 border rounded w-full"
              />
              <div className="flex justify-end">
                <button onClick={() => handleSubmit('password')} className="bg-blue-500 text-white px-4 py-2 rounded mr-2">Save</button>
                <button onClick={() => handleModal('password', false)} className="bg-gray-500 text-white px-4 py-2 rounded">Cancel</button>
              </div>
            </>
          )}
        </Modal>
      )}

      {showModal.email && (
        <Modal onClose={() => handleModal('email', false)}>
          <h3 className="text-xl font-bold mb-4">Edit Email</h3>
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            placeholder="New Email"
            className="input-field mb-4 p-2 border rounded w-full"
          />
          <div className="flex justify-end">
            <button onClick={() => handleSubmit('email')} className="bg-blue-500 text-white px-4 py-2 rounded mr-2">Save</button>
            <button onClick={() => handleModal('email', false)} className="bg-gray-500 text-white px-4 py-2 rounded">Cancel</button>
          </div>
        </Modal>
      )}

      {showModal.phone && (
        <Modal onClose={() => handleModal('phone', false)}>
          <h3 className="text-xl font-bold mb-4">Edit Phone Number</h3>
          <input
            type="text"
            name="phone"
            value={formData.phone}
            maxLength={10}
            onChange={handleChange}
            placeholder="New Phone Number"
            className="input-field mb-4 p-2 border rounded w-full"
          />
          <div className="flex justify-end">
            <button onClick={() => handleSubmit('phone')} className="bg-blue-500 text-white px-4 py-2 rounded mr-2">Save</button>
            <button onClick={() => handleModal('phone', false)} className="bg-gray-500 text-white px-4 py-2 rounded">Cancel</button>
          </div>
        </Modal>
      )}
    </div>
  );
};

const Modal = ({ children, onClose }) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white p-6 rounded-md shadow-md w-96 relative">
        <button onClick={onClose} className="absolute top-2 right-2 text-gray-500">X</button>
        {children}
      </div>
    </div>
  );
};

export default PersonalDetails;
