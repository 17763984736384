// Toast.js
import React, { useEffect, useState } from 'react';

const Toast = ({ message, onClose }) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (message) {
      setIsVisible(true); // Show the toast
      const timer = setTimeout(() => {
        setIsVisible(false); // Start fade out
        setTimeout(onClose, 500); // Wait for fade-out animation to finish before calling onClose
      }, 2000); // Display for 2 seconds

      return () => clearTimeout(timer);
    }
  }, [message, onClose]);

  const toastStyle = {
    position: 'fixed',
    top: '16px',
    right: '16px',
    backgroundColor: '#4A5568', // Dark gray
    color: 'white',
    padding: '16px',
    borderRadius: '8px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    opacity: isVisible ? 1 : 0,
    transition: 'opacity 0.5s ease-in-out',
    zIndex: 1000, // Ensure it appears above other content
  };

  return <div style={toastStyle}>{message}</div>;
};

export default Toast;
