// src/components/ManagerSection.jsx
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faEdit } from '@fortawesome/free-solid-svg-icons';

const initialManagers = [
  { id: 1, name: 'Aditya', email: 'aditya@example.com', phone: '1234567890' },
  { id: 2, name: 'Shivam', email: 'shivam@example.com', phone: '2345678901' },
  { id: 3, name: 'Abhishek', email: 'abhishek@example.com', phone: '3456789012' },
  // Add more managers as needed
];

export default function ManagerSection() {
  const navigate = useNavigate();
  const [managers, setManagers] = useState(initialManagers);

  const handleDeleteManager = (id) => {
    setManagers((prevManagers) => prevManagers.filter(manager => manager.id !== id));
  };

  return (
    <div className="flex flex-col lg:flex-row gap-6 p-4 bg-gray-100 min-h-screen">
      <div className="w-full bg-white p-6 rounded-lg shadow-lg">
        <div className="flex justify-between items-center mb-4">
          <h1 className="text-2xl font-bold text-gray-800">Manager Section</h1>
          <button
            className="bg-green-600 text-white py-2 px-4 rounded hover:bg-green-700 transition duration-100"
            onClick={() => navigate('/add-manager')} // Navigate to a form to add manager
          >
            Add Manager
          </button>
        </div>
        <table className="min-w-full border border-gray-300">
          <thead className="bg-gray-50">
            <tr>
              <th className="border border-gray-300 px-2 py-2 text-left">Manager Name</th>
              <th className="border border-gray-300 px-2 py-2 text-left">Email</th>
              <th className="border border-gray-300 px-2 py-2 text-left">Phone</th>
              <th className="border border-gray-300 px-2 py-2 text-left">Actions</th>
            </tr>
          </thead>
          <tbody className="bg-white">
            {managers.length > 0 ? (
              managers.map((manager) => (
                <tr key={manager.id} className="hover:bg-gray-100">
                  <td
                    className="border border-gray-300 px-4 py-2 cursor-pointer text-blue-500 hover:underline"
                    onClick={() => navigate(`/manager/${manager.id}`)}
                  >
                    {manager.name}
                  </td>
                  <td className="border border-gray-300 px-4 py-2">{manager.email}</td>
                  <td className="border border-gray-300 px-4 py-2">{manager.phone}</td>
                  <td className="border border-gray-300 px-4 py-2 flex space-x-2">
                    <button
                      className="text-blue-500 hover:text-blue-700 transition duration-300"
                      onClick={() => navigate(`/edit-manager/${manager.id}`)}
                    >
                      <FontAwesomeIcon icon={faEdit} />
                    </button>
                    <button
                      className="text-red-500 hover:text-red-700 transition duration-300"
                      onClick={() => handleDeleteManager(manager.id)}
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="4" className="border border-gray-300 px-4 py-2 text-center">
                  No managers found.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}
