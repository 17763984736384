import React, { createContext, useState, useEffect } from 'react';

export const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {
  // Initialize mode based on localStorage or default to 'Light'
  const [mode, setMode] = useState(() => {
    const savedMode = localStorage.getItem('mode');
    return savedMode !== null ? savedMode : 'Light';
  });

  // Update localStorage and toggle dark mode class on html element
  useEffect(() => {
    localStorage.setItem('mode', mode);

    if (mode === 'Dark') {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
  }, [mode]);

  // Toggle between 'Light' and 'Dark' modes
  const toggleMode = () => {
    setMode((prevMode) => (prevMode === 'Light' ? 'Dark' : 'Light'));
  };

  return (
    <ThemeContext.Provider value={{ mode, toggleMode }}>
      {children}
    </ThemeContext.Provider>
  );
};
