import React, { useEffect, useState } from 'react'
import { BASEURL } from './shared/Config';

function RecentShops() {
	const [recentShops, setrecentShops] = useState([]);
	useEffect(() => {
		const fetchShops = async () => {
			try {
				const response = await fetch(`${BASEURL}shops?page=${1}&limit=${5}&sortBy=createdAt:desc`, {
					method: 'GET',
					headers: {
						'Content-Type': 'application/json',
						'Authorization': "Bearer " + localStorage.getItem("token")
					},
				});
				const data = await response.json();
				setrecentShops(data.shops); // Extract the users array


			} catch (error) {
				console.error('Error fetching managers:', error);
			}
		};

		fetchShops();
	}, []);
	return (
		<div className="w-[20rem] bg-white p-4 rounded-sm border border-gray-200">
			<strong className="text-gray-700 font-medium">Recently Added Shops</strong>
			<div className="mt-4 flex flex-col gap-3">
				{recentShops?.map((shop, index) => (
					<div key={shop._id} className="flex flex-col ">
						<div className="ml-4 flex-1">
							<p className="text-sm text-gray-800 font-bold">{shop.name}</p>
							<p className="text-xs text-gray-600"><span className="text-xs text-gray-600 font-bold">Manager:</span> {shop.owner}</p>
							<p className="text-xs text-gray-600"><span className="text-xs text-gray-600 font-bold">Added:</span> {shop.createdAt.split('T')[0]}</p>
						</div>
						{index < recentShops.length - 1 && (
							<div className="border-t border-gray-200 mt-3"></div>
						)}
					</div>
				))}
			</div>

		</div>
	)
}

export default RecentShops
