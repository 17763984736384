import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import Loader from './shared/Loader';
import { BASEURL } from './shared/Config';

const SalesExecutiveProfile = () => {
  const { id } = useParams();
  const location = useLocation();
  const { data } = location.state || {};
  const [loading, setLoading] = useState(false);
  const [deliveryAgentList, setDeliveryAgentList] = useState([]);
  const [shopList, setShopList] = useState([]);

  useEffect(() => {
    setLoading(true);
    fetchExecutives();
  }, []);

  const fetchExecutives = async (page = 1) => {
    try {
      const response = await fetch(`${BASEURL}sales-executive/${id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': "Bearer " + localStorage.getItem("token"),
        },
      });
      const data = await response.json();
      setLoading(false);
      setDeliveryAgentList(data?.deliveryPersons || []);
      setShopList(data?.shops || []);
    } catch (error) {
      console.error('Error fetching sales executives:', error);
      setLoading(false);
    }
  };

  return (
    <div className="flex flex-col gap-6 p-4 bg-gray-100 min-h-screen">
      {loading && <Loader />}
      <div className="w-full flex items-center justify-between bg-white p-6 rounded-lg shadow-lg">
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <img
            src={data?.photo ? data.photo : require('../components/shared/img/dummyProfile.png')}
            alt="Profile"
            className="w-16 h-16 object-cover rounded-full"
          />
          <h1 className="text-2xl font-bold text-black ml-4">{data?.name}'s Profile</h1>
        </div>
        <div className="text-black">
          <p><strong>User ID:</strong> {data?._id}</p>
          <p><strong>Email:</strong> {data?.email}</p>
          <p><strong>Phone:</strong> {data?.mobile}</p>
          <p><strong>Address:</strong> {data?.address}</p>
        </div>
      </div>

      <div className="w-full bg-white p-6 rounded-lg shadow-lg">
        <h2 className="text-xl font-bold text-black mb-4">Delivery Boy List</h2>
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-black uppercase tracking-wider">Name</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-black uppercase tracking-wider">Email</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-black uppercase tracking-wider">Mobile</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-black uppercase tracking-wider">Status</th>
              </tr>
            </thead>
            {deliveryAgentList.length === 0 ? (
              <tbody>
                <tr>
                  <td colSpan="4" className="px-6 py-4 text-center text-black">No Delivery Boy Available</td>
                </tr>
              </tbody>
            ) : (
              <tbody className="bg-white divide-y divide-gray-200">
                {deliveryAgentList.map(executive => (
                  <tr key={executive._id}>
                    <td className="px-6 py-4 whitespace-nowrap text-black">{executive.name}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-black">{executive.email}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-black">{executive.mobile}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-green-500">{executive.status}</td>
                  </tr>
                ))}
              </tbody>
            )}
          </table>

          <h2 className="text-xl font-bold text-black mt-10">Shop List</h2>
          <table className="min-w-full divide-y divide-gray-200 mt-4">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-black uppercase tracking-wider">Shop Name</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-black uppercase tracking-wider">No. of Orders</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-black uppercase tracking-wider">Contact Number</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-black uppercase tracking-wider">City</th>
              </tr>
            </thead>
            {shopList.length === 0 ? (
              <tbody>
                <tr>
                  <td colSpan="4" className="px-6 py-4 text-center text-black">No Shops Available</td>
                </tr>
              </tbody>
            ) : (
              <tbody className="bg-white divide-y divide-gray-200">
                {shopList.map(shop => (
                  <tr key={shop._id}>
                    <td className="px-6 py-4 whitespace-nowrap text-black">{shop.name}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-black">{shop.noOfOrders}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-black">{shop.contactNumber}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-black">{shop.address?.city}</td>
                  </tr>
                ))}
              </tbody>
            )}
          </table>
        </div>
      </div>
    </div>
  );
};

export default SalesExecutiveProfile;
