import React from 'react';

export default function Support() {
  return (
    <div className="bg-white shadow-md rounded-lg p-6 max-w-3xl mx-auto mt-8">
      <h1 className="text-3xl font-extrabold text-gray-900 mb-4">Help and Support</h1>
      <p className="text-gray-700 text-lg mb-6">
        Welcome to our Help and Support section! We're here to assist you with any questions or issues you may have. Whether you're facing technical difficulties, need help managing your account, or have other inquiries, you can find valuable resources and guides here.
      </p>
      <p className="text-gray-700 text-lg mb-4">
        For personalized assistance, feel free to <a href="mailto:hello@thundergits.com" className="text-blue-600 hover:underline font-semibold">contact us directly</a>. Our support team is ready to help you resolve any issues and ensure you have a smooth experience.
      </p>
      <div className="mt-4">
        <p className="text-gray-500 text-sm">
          Thank you for reaching out to us. Your satisfaction is our priority!
        </p>
      </div>
    </div>
  );
}
