import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import Loader from './shared/Loader';
import { BASEURL } from './shared/Config';

const DeliveryAgentProfile = () => {
  const { id } = useParams();
  const location = useLocation();
  const { data } = location.state || {};
  const [loading, setLoading] = useState(false);
  const [ordersList, setOrdersList] = useState([]);

  useEffect(() => {
    setLoading(true);
    fetchOrders();
  }, []);

  const fetchOrders = async () => {
    try {
      const response = await fetch(`${BASEURL}orders?page=1&limit=50&deliveryPerson=${id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': "Bearer " + localStorage.getItem("token"),
        },
      });
      const data = await response.json();
      setOrdersList(data?.orders || []);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching orders:', error);
      setLoading(false);
    }
  };

  return (
    <div className="flex flex-col gap-6 p-4 bg-gray-100 min-h-screen">
      {loading && <Loader />}
      <div className="w-full flex items-center justify-between bg-white p-6 rounded-lg shadow-lg">
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <img
            src={data?.photo ? data.photo : require('../components/shared/img/dummyProfile.png')}
            alt="Profile"
            className="w-16 h-16 object-cover rounded-full"
          />
          <h1 className="text-2xl font-bold text-black ml-4">{data?.name}'s Profile</h1>
        </div>
        <div className="text-black">
          <p><strong>User ID:</strong> {data?._id}</p>
          <p><strong>Email:</strong> {data?.email}</p>
          <p><strong>Phone:</strong> {data?.mobile}</p>
          <p><strong>Address:</strong> {data?.address}</p>
        </div>
      </div>

      <div className="w-full bg-white p-6 rounded-lg shadow-lg">
        <h2 className="text-xl font-bold text-black mb-4">Order Details</h2>
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-black uppercase tracking-wider">Order ID</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-black uppercase tracking-wider">Order Name</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-black uppercase tracking-wider">Status</th>
              </tr>
            </thead>
            {ordersList.length === 0 ? (
              <tbody>
                <tr>
                  <td colSpan="3" className="px-6 py-4 text-center text-black">No Orders Available</td>
                </tr>
              </tbody>
            ) : (
              <tbody className="bg-white divide-y divide-gray-200">
                {ordersList.map(order => (
                  <tr key={order._id}>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-black">{order._id}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-black">{order?.items[0]?.inventory?.item.name}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-black">{order.orderStatus.replaceAll('_', ' ')}</td>
                  </tr>
                ))}
              </tbody>
            )}
          </table>
        </div>
      </div>
    </div>
  );
};

export default DeliveryAgentProfile;
