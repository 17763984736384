import React, { useState, useEffect } from 'react';
import Loader from './shared/Loader';
import { BASEURL } from './shared/Config';

export default function Shops({ searchQuery }) {
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const fetchItems = async (page = 1) => {
    try {
      setLoading(true);
      const response = await fetch(`${BASEURL}shops?page=${page}&limit=${10}&sortBy=createdAt:desc`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
      });
      const data = await response.json();
      if (!data.error) {
        setItems(data.shops);
        setCurrentPage(Number(data.currentPage));
        setTotalPages(Number(data.totalPages));
      } else {
        console.log(data.error);
      }
    } catch (error) {
      console.error('Error fetching items:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchItems();
  }, []);

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      fetchItems(newPage);
    }
  };

  let filteredItems = items;
  if (searchQuery.length > 2) {
    filteredItems = items.filter(item =>
      item.name.toLowerCase().includes(searchQuery.toLowerCase())
    );
  }

  return (
    <div className="flex flex-col lg:flex-row gap-6 ">
      {loading && <Loader />}
      <div className="w-full bg-white p-6 rounded-lg shadow-lg">
        <div className="flex justify-between items-center mb-4">
          <h1 className="text-gray-900 text-2xl font-bold">Shops List</h1>
        </div>
        <table className="min-w-full border border-gray-300">
          <thead className="bg-gray-50">
            <tr>
              <th className="text-gray-800 border border-gray-300 px-2 py-2">Shop ID</th>
              <th className="text-gray-800 border border-gray-300 px-2 py-2">Shop Name</th>
              <th className="text-gray-800 border border-gray-300 px-2 py-2">Shop Number</th>
              <th className="text-gray-800 border border-gray-300 px-2 py-2">Owner Name</th>
              <th className="text-gray-800 border border-gray-300 px-2 py-2">No of Orders</th>
              <th className="text-gray-800 border border-gray-300 px-2 py-2">City</th>
              <th className="text-gray-800 border border-gray-300 px-2 py-2">Address</th>
              <th className="text-gray-800 border border-gray-300 px-2 py-2">Paid Amount</th>
              <th className="text-gray-800 border border-gray-300 px-2 py-2">Pending Amount</th>
              <th className="text-gray-800 border border-gray-300 px-2 py-2">Total Amount</th>
              <th className="text-gray-800 border border-gray-300 px-2 py-2">Operating Days</th>
            </tr>
          </thead>
          <tbody className="bg-white">
            {filteredItems?.map((item) => (
              <tr key={item?._id}>
                {console.log('manish----',item)}
                <td className="text-gray-800 border border-gray-300 px-4 py-2">{item?._id}</td>
                <td className="text-gray-800 border border-gray-300 px-4 py-2">{item?.name}</td>
                <td className="text-gray-800 border border-gray-300 px-4 py-2">{item?.contactNumber}</td>
                <td className="text-gray-800 border border-gray-300 px-4 py-2">{item?.owner}</td>
                <td className="text-gray-800 border border-gray-300 px-4 py-2">{item?.noOfOrders}</td>
                <td className="text-gray-800 border border-gray-300 px-4 py-2">{item?.area}</td>
                <td className="text-gray-800 border border-gray-300 px-4 py-2">{item.address?.addressLine1}, {item.address?.addressLine2}{item.address?.city}, {item.address?.state}, {item.address?.country}</td>
                <td className="text-gray-800 border border-gray-300 px-4 py-2">{item?.amountPaid}</td>
                <td className="text-gray-800 border border-gray-300 px-4 py-2">{item?.pendingAmount}</td>
                <td className="text-gray-800 border border-gray-300 px-4 py-2">{item?.totalAmount}</td>
                <td className="text-gray-800 border border-gray-300 px-4 py-2">
                  {item.operatingDays.map((day, index) => (
                    <span key={index}>{day}{index < item.operatingDays.length - 1 ? ', ' : ''}</span>
                  ))}
                </td>

              </tr>
            ))}
          </tbody>
        </table>
        <div className="flex justify-between items-center mt-4">
          <button
            className="bg-gray-300 text-gray-800 py-2 px-4 rounded disabled:opacity-50"
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
          >
            Previous
          </button>
          <span className="text-black">
            Page {currentPage} of {totalPages == 0 ? 1 : totalPages}
          </span>
          <button
            className="bg-gray-300 text-gray-800 py-2 px-4 rounded disabled:opacity-50"
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
}
