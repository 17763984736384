import React, { useState } from 'react';
import Loader from './shared/Loader';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai'; // Import eye icons for show/hide functionality
import { useNavigate } from 'react-router-dom';
import { BASEURL } from './shared/Config';

const ChangePassword = () => {
  const navigate = useNavigate();
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [showOldPassword, setShowOldPassword] = useState(false); // State to toggle old password visibility
  const [showNewPassword, setShowNewPassword] = useState(false); // State to toggle new password visibility
  const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false); // State to toggle confirm new password visibility

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    // Validate passwords
    if (!oldPassword || !newPassword || !confirmNewPassword) {
      setError('All fields are required.');
      setLoading(false);
      return;
    }
    if (newPassword !== confirmNewPassword) {
      setError('New password and confirmation do not match.');
      setLoading(false);
      return;
    }
    if (newPassword.length < 6) {
      setError('New password must be at least 6 characters long.');
      setLoading(false);
      return;
    }

    try {
      const response = await fetch(`${BASEURL}auth/change-password`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': "Bearer " + localStorage.getItem("token"),
        },
        body: JSON.stringify({ oldPassword, newPassword, confirmNewPassword }),
      });

      // Check if response is JSON
      const contentType = response.headers.get('content-type');
      if (contentType && contentType.includes('application/json')) {
        const result = await response.json();
        if (response.ok) {
          console.log('Password updated successfully:', result);
          alert('Password updated successfully');
          navigate('/');
          setOldPassword('');
          setNewPassword('');
          setConfirmNewPassword('');
          setError('');
        } else {
          console.error('Error updating password:', result);
          setError('Failed to update password: ' + result.message);
        }
      } else {
        // Handle non-JSON response (e.g., HTML error page)
        const text = await response.text();
        console.error('Non-JSON response:', text);
        setError('An unexpected error occurred.');
      }
    } catch (error) {
      console.error('Fetch error:', error);
      setError('Error: ' + error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className='flex items-center justify-center min-h-screen bg-gray-100'>
      <div className='w-11/12 max-w-[700px] px-10 py-20 rounded-3xl bg-white border-2 border-gray-100'>
        {loading && <Loader />}
        <h1 className='text-4xl font-semibold text-black'>Change Password</h1>
        <p className='font-medium text-lg text-gray-500 mt-4'>Enter your details to change your password.</p>
        <form onSubmit={handleSubmit} className='mt-8'>
          <div className='flex flex-col'>
            <label className='text-lg font-medium text-black'>Old Password</label>
            <div className='relative'>
              <input 
                className='w-full border-2 border-gray-100 rounded-xl p-4 mt-1 bg-transparent text-black'
                placeholder="Enter your old password"
                type={showOldPassword ? 'text' : 'password'} // Toggle old password type
                value={oldPassword}
                onChange={(e) => setOldPassword(e.target.value)}
              />
              <button
                type="button"
                onClick={() => setShowOldPassword(!showOldPassword)} // Toggle old password visibility
                className='absolute inset-y-0 right-4 flex items-center'
                aria-label={showOldPassword ? 'Hide old password' : 'Show old password'}
              >
                {showOldPassword ? <AiOutlineEyeInvisible color='black' size={24} /> : <AiOutlineEye color='black'  size={24} />}
              </button>
            </div>
          </div>
          <div className='flex flex-col mt-4'>
            <label className='text-lg font-medium text-black'>New Password</label>
            <div className='relative'>
              <input 
                className='w-full border-2 border-gray-100 rounded-xl p-4 mt-1 bg-transparent text-black'
                placeholder="Enter your new password"
                type={showNewPassword ? 'text' : 'password'} // Toggle new password type
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
              />
              <button
                type="button"
                onClick={() => setShowNewPassword(!showNewPassword)} // Toggle new password visibility
                className='absolute inset-y-0 right-4 flex items-center'
                aria-label={showNewPassword ? 'Hide new password' : 'Show new password'}
              >
                {showNewPassword ?<AiOutlineEyeInvisible color='black' size={24} /> : <AiOutlineEye color='black'  size={24} />}
              </button>
            </div>
          </div>
          <div className='flex flex-col mt-4'>
            <label className='text-lg font-medium text-black'>Confirm New Password</label>
            <div className='relative'>
              <input 
                className='w-full border-2 border-gray-100 rounded-xl p-4 mt-1 bg-transparent text-black'
                placeholder="Confirm your new password"
                type={showConfirmNewPassword ? 'text' : 'password'} // Toggle confirm new password type
                value={confirmNewPassword}
                onChange={(e) => setConfirmNewPassword(e.target.value)}
              />
              <button
                type="button"
                onClick={() => setShowConfirmNewPassword(!showConfirmNewPassword)} // Toggle confirm new password visibility
                className='absolute inset-y-0 right-4 flex items-center'
                aria-label={showConfirmNewPassword ? 'Hide confirm new password' : 'Show confirm new password'}
              >
                {showConfirmNewPassword ? <AiOutlineEyeInvisible color='black' size={24} /> : <AiOutlineEye color='black'  size={24} />}
              </button>
            </div>
          </div>

          {error && <p style={{ color: 'red' }}>{error}</p>}

          <div className='mt-8 flex flex-col gap-y-4'>
            <button type="submit" className='active:scale-[.98] active:duration-75 transition-all hover:scale-[1.01] ease-in-out transform py-4 bg-violet-500 rounded-xl text-white font-bold text-lg'>
              Change Password
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ChangePassword;
