import React, { useState, Fragment, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faEdit } from '@fortawesome/free-solid-svg-icons';
import Loader from './shared/Loader';
import { BASEURL } from './shared/Config';

const initialProductState = {
  item: '',
  quantity: '',
  city: '',
  pricePerUnit: '',
  unit: 'l',
  mrp: '',
  expiryDate: '',
  manufacturingDate: ''
};

const cities = [
  'Patna', 'Jamshedpur', 'Ranchi', // Indian cities
  'Gwalior', 'Delhi', 'Bengaluru', 'Kolkata', 'Jaipur'
];

export default function Inventory({ searchQuery = '' }) {
  const [isOpen, setIsOpen] = useState(false);
  const [newProduct, setNewProduct] = useState(initialProductState);
  const [editingProductId, setEditingProductId] = useState(null);
  const [products, setProducts] = useState([]);
  const [itemNames, setItemNames] = useState([]);
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedName, setSelectedName] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [pageSize] = useState(10); // Number of items per page
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [cities, setCities] = useState([])
  const openModal = (product = initialProductState) => {
    setIsOpen(true);
    setSelectedName(product?.item?.name)
    console.log('produ1111ct', product)
    setNewProduct(product);
    setEditingProductId(product._id || null);
  };
  const fetchCity = async (page = 1) => {
    try {
      setLoading(true);
      const response = await fetch(`${BASEURL}cities`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
      });
      const data = await response.json();

      if (!data.error) {
        setCities(data.map(i=>i.name));
      } else {
        console.log(data.error);
      }
    } catch (error) {
      console.error('Error fetching items:', error);
    } finally {
      setLoading(false);
    }
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const fetchItems = async () => {
    try {
      const response = await fetch(`${BASEURL}items`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem("token")}`
        },
      });

      const data = await response.json();
      const names = data.items.map(item => item.name);

      setItems(data.items);
      setItemNames(names);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error('Error fetching items:', error);
    }
  };

  useEffect(() => {
    setLoading(true);
    fetchItems();
    fetchCity()
  }, []);

  const fetchInventories = async (page = 1) => {
    try {
      const response = await fetch(`${BASEURL}inventory?page=${page}&limit=${pageSize}&sortBy=createdAt:desc`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem("token")}`
        },
      });

      const data = await response.json();
      setProducts(data.inventoryItems);
      setTotalPages(data.totalPages); // Assuming your API response includes totalPages
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error('Error fetching inventories:', error);
    }
  };

  useEffect(() => {
    setLoading(true)
    fetchInventories(currentPage);
  }, [currentPage]);

  let filteredProducts = products;
  if (searchQuery.length > 2) {
    filteredProducts = products.filter(product =>
      product.item.name.toLowerCase().includes(searchQuery.toLowerCase())
    );
  }

  useEffect(() => {
    const item = items.find(item => item._id === newProduct.item);
    // setSelectedName(item ? item.name : '');
  }, [newProduct.item, items]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewProduct(prevState => ({ ...prevState, [name]: value }));
  };

  const handleSelectChange = (e) => {
    const selectedName = e.target.value;
    const selectedItem = items.find(item => item.name === selectedName);
    setNewProduct(prevState => ({ ...prevState, item: selectedItem ? selectedItem._id : '' }));
    setSelectedName(selectedName);
  };

  const handleAddOrUpdateProduct = async () => {
    const isFormComplete = Object.values(newProduct).every(value => String(value).trim() !== '');
    if (isFormComplete) {
      if (editingProductId) {
        const response = await fetch(`${BASEURL}inventory/${editingProductId}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem("token")}`
          },
          body: JSON.stringify({
            city: newProduct.city,
            quantity: newProduct.quantity,
            // pricePerUnit: Object.prototype.toString.call(newProduct.pricePerUnit) != '[object Object]' ? newProduct.pricePerUnit : newProduct.pricePerUnit.amount,
            mrp: Number(newProduct.mrp),
            manufacturingDate: newProduct.manufacturingDate,
            expiryDate: newProduct.expiryDate,
          })
        });
        const data = await response.json();
        if (response.status == 200) {

          setProducts((prevProducts) =>
            prevProducts.map((product) =>
              product._id === editingProductId ? { ...newProduct, _id: editingProductId } : product
            )
          );
          closeModal();

        } else {
          alert(data.message)
        }

      } else {
        const response = await fetch(`${BASEURL}inventory`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem("token")}`
          },
          body: JSON.stringify({
            item: newProduct.item,
            unit: newProduct.unit,
            city: newProduct.city,
            quantity: newProduct.quantity,
            pricePerUnit: newProduct.pricePerUnit,
            mrp: Number(newProduct.mrp),
            manufacturingDate: newProduct.manufacturingDate,
            expiryDate: newProduct.expiryDate
          })
        });
        const newProductFromResponse = await response.json();
        console.log('newProductFromResponse', response)
        if (response.status == 201) {
          const fullItem = items.find(item => item._id === newProduct.item);
          newProductFromResponse.inventoryItem.item = fullItem;
          setProducts([newProductFromResponse.inventoryItem, ...products]);
          closeModal();

        } else if (response.status == 400) {
        console.log('newProductFromResponse1111', newProductFromResponse)
          alert(newProductFromResponse.errors[0]?.message)
        } else {
          alert(newProductFromResponse.message)
        }
      }
    } else {
      alert('Please fill out all fields.');
    }
  };

  const handleDeleteProduct = async (id) => {
    const response = await fetch(`${BASEURL}inventory/${id}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem("token")}`
      },
    });
    const data = await response.json();
    console.log('responseresponse', data)
    if (response.status == 200) {

      setProducts((prevProducts) => prevProducts.filter(product => product._id !== id));

    } else if (response.status == 400) {
      alert(data.message)
    }

    setIsDeleteConfirmationOpen(false)
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const openDeleteConfirmation = (id) => {
    setItemToDelete(id);
    setIsDeleteConfirmationOpen(true);
  };

  // Use this function when clicking the delete button
  const handleDeleteButtonClick = (id) => {
    openDeleteConfirmation(id);
  };

  return (
    <div className="flex flex-col lg:flex-row gap-6">
      {loading && <Loader />}
      <div className="w-full bg-white p-6 rounded-lg shadow-lg">
        <div className="flex justify-between items-center mb-4">
          <h1 style={{color:'black'}} className="text-2xl font-bold">Inventory</h1>
          <button
            className="bg-green-600 text-white py-2 px-4 rounded hover:text-white hover:bg-green-700 transition duration-100"
            onClick={() => openModal()}
          >
            Add Product
          </button>
        </div>
        <table className="min-w-full border border-gray-300">
          <thead className="bg-gray-50">
            <tr>
              <th className="text-gray-800 border border-gray-300 px-2 py-2">Product ID</th>
              <th className="text-gray-800 border border-gray-300 px-2 py-2">Product Name</th>
              <th className="text-gray-800 border border-gray-300 px-2 py-2">Quantity</th>
              <th className="text-gray-800 border border-gray-300 px-2 py-2">Cost</th>
              <th className="text-gray-800 border border-gray-300 px-2 py-2">Manufacturing Date</th>
              <th className="text-gray-800 border border-gray-300 px-2 py-2">Expiry Date</th>
              <th className="text-gray-800 border border-gray-300 px-2 py-2">MRP</th>
              <th className="text-gray-800 border border-gray-300 px-2 py-2">City</th>
              <th className="text-gray-800 border border-gray-300 px-2 py-2">Actions</th>
            </tr>
          </thead>
          <tbody className="bg-white">
            {filteredProducts.map((product) => (
              <tr key={product._id}>
                <td className="text-gray-800 border border-gray-300 px-4 py-2">{product._id}</td>
                <td className="text-gray-800 border border-gray-300 px-4 py-2">{product.item.name}</td>
                <td className="text-gray-800 border border-gray-300 px-4 py-2">{product.quantity}</td>
                <td className="text-gray-800 border border-gray-300 px-4 py-2">{product.pricePerUnit.amount} {product.pricePerUnit.currency}</td>
                <td className="text-gray-800 border border-gray-300 px-4 py-2">{product.manufacturingDate?product.manufacturingDate.split('T')[0]:'N/A'}</td>
                <td className="text-gray-800 border border-gray-300 px-4 py-2">{product.expiryDate?product.expiryDate.split('T')[0]:'N/A'}</td>
                <td className="text-gray-800 border border-gray-300 px-4 py-2">{product.mrp?product.mrp:'N/A'}</td>
                <td className="text-gray-800 border border-gray-300 px-4 py-2">{product.city}</td>
                <td className="text-gray-800 border border-gray-300 px-4 py-2 flex space-x-2">
                  <button
                    className="text-blue-500 hover:text-blue-700 transition duration-300"
                    onClick={() => openModal(product)}
                  >
                    <FontAwesomeIcon icon={faEdit} />
                  </button>
                  <button
                    className="text-red-500 hover:text-red-700 transition duration-300"
                    onClick={() => handleDeleteButtonClick(product._id)}
                  >
                    <FontAwesomeIcon icon={faTrash} />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="flex justify-between items-center mt-4">
          <button
            className="bg-gray-300 text-gray-800 py-2 px-4 rounded disabled:opacity-50"
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
          >
            Previous
          </button>
          <span className="text-black">
            Page {currentPage} of {totalPages == 0 ? 1 : totalPages}
          </span>
          <button
            className="bg-gray-300 text-gray-800 py-2 px-4 rounded disabled:opacity-50"
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
          >
            Next
          </button>
        </div>
      </div>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>
          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-full p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-4xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900 text-center">
                    {editingProductId ? 'Update Product' : 'Add Product'}
                  </Dialog.Title>
                  <div className="grid grid-cols-1 gap-4 mt-4">
                    <div className="grid grid-cols-2 gap-4">
                      <div>
                        <label className="block mb-2">Product Name</label>
                        <select
                          name="item"
                          value={selectedName || ''}
                          onChange={handleSelectChange}
                          className="w-full px-2 py-1 border rounded"
                          disabled={!!editingProductId}
                        >
                          <option value="" disabled>Select Product</option>
                          {itemNames.map((itemName, index) => (
                            <option key={index} value={itemName}>{itemName}</option>
                          ))}
                        </select>
                      </div>
                      <div>
                        <label className="block mb-2">City</label>
                        <select
                          name="city"
                          value={newProduct.city}
                          onChange={handleInputChange}
                          className="w-full px-2 py-1 border rounded"
                        >
                          <option value="" disabled>Select City</option>
                          {cities.map((city, index) => (
                            <option key={index} value={city}>{city}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="grid grid-cols-2 gap-4">
                      <div>
                        <label className="block mb-2">Quantity</label>
                        <input
                          type="text"
                          name="quantity"
                          value={newProduct.quantity}
                          onChange={handleInputChange}
                          className="w-full px-2 py-1 border rounded"
                        />
                      </div>
                      {<div>
                        <label className="block mb-2">Cost</label>
                        <input
                          type="text"
                          disabled={!!editingProductId}
                          name="pricePerUnit"
                          value={newProduct.pricePerUnit.amount}
                          onChange={handleInputChange}
                          className="w-full px-2 py-1 border rounded"
                        />
                      </div>}
                    </div>
                    {console.log('newProductnewProductnewProduct', newProduct)}
                    <div className="grid grid-cols-2 gap-4">
                      <div>
                        <label className="block mb-2">MRP</label>
                        <input
                          type="text"
                          name="mrp"
                          value={newProduct.mrp}
                          onChange={handleInputChange}
                          className="w-full px-2 py-1 border rounded"
                        />
                      </div>
                      {<div>
                        <label className="block mb-2">Manufacturing Date</label>
                        {console.log('newProduct.manufacturingDate', newProduct.manufacturingDate)}
                        <input
                          type="date"
                          name="manufacturingDate"
                          value={newProduct.manufacturingDate ? newProduct.manufacturingDate.split('T')[0] : ''}
                          onChange={handleInputChange}
                          className="w-full px-2 py-1 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                      </div>}
                      {<div>
                        <label className="block mb-2">Expiry Date</label>
                        <input
                          type="date"
                          name="expiryDate"
                          value={newProduct.expiryDate ? newProduct.expiryDate.split('T')[0] : ''}
                          onChange={handleInputChange}
                          className="w-full px-2 py-1 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                      </div>}
                    </div>
                  </div>

                  <div className="mt-4 flex justify-end gap-4">
                    <button
                      className="bg-gray-500 text-white py-2 px-4 rounded"
                      onClick={closeModal}
                    >
                      Cancel
                    </button>
                    <button
                      className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-700 transition duration-300"
                      onClick={handleAddOrUpdateProduct}
                    >
                      {editingProductId ? 'Update' : 'Add'}
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
      <Transition appear show={isDeleteConfirmationOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={() => setIsDeleteConfirmationOpen(false)}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-full p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title as="h2" className="text-lg mb-4 font-bold leading-6 text-gray-900 text-center">
                    Confirm Delete
                  </Dialog.Title>
                  <div className="mt-2">
                    <p style={{ textAlign: 'center', marginBottom: 20 }}>Are you sure you want to delete this Inventory?</p>
                  </div>
                  <div className="mt-4 flex justify-end">

                    <button
                      type="button"
                      className="bg-gray-300 text-gray-700 px-4 py-2 rounded hover:bg-gray-400 ml-2"
                      onClick={() => setIsDeleteConfirmationOpen(false)}
                    >
                      Cancel
                    </button>

                    <button
                      type="button"
                      className="bg-red-600 text-white px-4 py-2 ml-4 rounded hover:bg-red-700"
                      onClick={() => handleDeleteProduct(itemToDelete)}
                    >
                      Delete
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
}
