import classNames from 'classnames';
import React, { useState } from 'react';
import { FcBullish } from 'react-icons/fc';
import { DASHBOARD_SIDEBAR_LINKS, DASHBOARD_SIDEBAR_BOTTOM_LINKS } from '../../lib/consts/navigation';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { HiOutlineLogout, HiChevronRight, HiChevronLeft } from 'react-icons/hi';
import logo from '../shared/img/logo.png'; // Adjust the import path

const linkClasses = 'flex items-center gap-2 font-light px-3 py-2 hover:bg-neutral-700 hover:no-underline active:bg-neutral-600 rounded-sm text-base';

export default function Sidebar() {
  const [collapsed, setCollapsed] = useState(false);
  const navigate = useNavigate(); // For navigation after logout

  const handleLogout = () => {
    // Clear the token and expiration time from local storage
    localStorage.removeItem('token');
    localStorage.removeItem('tokenExpiration');
    
    // Optionally, you could also clear other user-related data

    // Notify the app of logout (if needed) and redirect to login page
    navigate('/login');
  };

  return (
    <div className={classNames('bg-neutral-900 h-screen p-3 flex flex-col text-white transition-width duration-300', {
      'w-50': !collapsed,
      'w-25': collapsed,
    })}>
      <div className='flex items-center justify-between px-1 py-3'>
        <div className='flex items-center gap-2'>
          <img src={logo} alt="logo" className='w-10 h-10 rounded-2xl' /> {/* Use the imported image */}
          {!collapsed && <span className='text-neutral-100 text-sm'>Crumbell</span>}
        </div>
        <button onClick={() => setCollapsed(!collapsed)} className='text-neutral-100'>
          {collapsed ? <HiChevronRight fontSize={24} /> : <HiChevronLeft fontSize={24} />}
        </button>
      </div>
      <div className='flex-1 py-8 flex flex-col gap-0.5'>
        {DASHBOARD_SIDEBAR_LINKS.map((item) => (
          <SidebarLink key={item.key} item={item} collapsed={collapsed} />
        ))}
      </div>
      <div className='flex flex-col gap-0.5 pt-2 border-t border-neutral-700'>
        {DASHBOARD_SIDEBAR_BOTTOM_LINKS.map(item => (
          <SidebarLink key={item.key} item={item} collapsed={collapsed} />
        ))}
        <div
          onClick={handleLogout}
          className={classNames('text-red-500 cursor-pointer', linkClasses, { 'justify-center': collapsed })}
        >
          <span className='text-xl'><HiOutlineLogout /></span>
          {!collapsed && <span>Logout</span>}
        </div>
      </div>
    </div>
  );
}

function SidebarLink({ item, collapsed }) {
  const { pathname } = useLocation();

  return (
    <Link to={item.path} className={classNames(pathname === item.path ? 'bg-neutral-700 text-white' : 'text-neutral-400', linkClasses, { 'justify-center': collapsed })}>
      <span className='text-xl'>{item.icon}</span>
      {!collapsed && <span>{item.label}</span>}
    </Link>
  );
}
