import React, { useState, useEffect } from 'react';
import { HashRouter as Router, Routes, Route, Navigate } from 'react-router-dom'; // Changed to HashRouter
import Item from './components/Item';
import Layout from './components/shared/Layout';
import Dashboard from './components/Dashboard';
import SalesExecutive from './components/SalesExecutive';
import DeliveryBoy from './components/DeliveryBoy';
import GeneralSettings from './components/GeneralSettings';
import Order from './components/Order';
import Shops from './components/Shops';
import PersonalDetails from './components/PersonalDetails';
import Setting from './components/Setting';
import Manager from './components/Manager';
import Form from './components/Form';
import Inventory from './components/Inventory';
import { ThemeProvider } from './components/ThemeContext';
import ManagerSection from './components/ManagerSection';
import ManagerProfile from './components/ManagerProfile';
import OrderTracking from './components/OrderTracking';
import SalesExecutiveProfile from './components/SalesExecutiveProfile';
import DeliveryAgentProfile from './components/DeliveryAgentProfile';
import Support from './components/Support';
import ChangePassword from './components/ChangePassword';
import { BASEURL } from './components/shared/Config';
import City from './components/City';

const isTokenExpired = () => {
  const expiration = localStorage.getItem("tokenExpiration");
  if (!expiration) return true;
  const now = new Date().getTime();
  return now > parseInt(expiration);
};

const getToken = () => {
  if (isTokenExpired()) {
    console.log("Token expired or not found");
    return null;
  }
  return localStorage.getItem("token");
};

const refreshToken = async () => {
  const token = getToken();
  if (!token) return;

  try {
    const response = await fetch(`${BASEURL}auth/refresh`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });

    if (response.ok) {
      const result = await response.json();
      localStorage.setItem("token", result.token); // Save the new token
      localStorage.setItem("tokenExpiration", result.expiration); // Save new token expiration time
    } else {
      console.error('Failed to refresh token');
    }
  } catch (error) {
    console.error('Refresh token error:', error);
  }
};

function App() {
  const [authState, setAuthState] = useState(getToken() ? 'authenticated' : 'unauthenticated');
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    if (authState === 'authenticated') {
      if (isTokenExpired()) {
        refreshToken().then(() => {
          if (isTokenExpired()) {
            setAuthState('unauthenticated');
          }
        });
      }
    }
  }, [authState]);

  return (
    <ThemeProvider>
      <Router>
        <Routes>
          {authState === 'authenticated' ? (
            <Route path="/" element={<Layout searchQuery={searchQuery} setSearchQuery={setSearchQuery} />}>
              <Route index element={<Dashboard />} />
              <Route path="/order" element={<Order searchQuery={searchQuery} />} />
              <Route path="/shop" element={<Shops searchQuery={searchQuery} />} />
              <Route path="/city" element={<City searchQuery={searchQuery} />} />
              <Route path="/personal-details" element={<PersonalDetails />} />
              <Route path="/sales-executive" element={<SalesExecutive searchQuery={searchQuery} />} />
              <Route path="/delivery-boy" element={<DeliveryBoy searchQuery={searchQuery} />} />
              <Route path="/manager" element={<Manager searchQuery={searchQuery}/>} />
              <Route path="/inventory" element={<Inventory searchQuery={searchQuery}/>} />
              <Route path="/item" element={<Item searchQuery={searchQuery}/>} />
              <Route path="/settings" element={<Setting />} />
              <Route path="/manager-section" element={<ManagerSection />} />
              <Route path="/manager/:id" element={<ManagerProfile />} />
              <Route path="/sales-executive/:id" element={<SalesExecutiveProfile />} />
              <Route path="/delivery-boy/:id" element={<DeliveryAgentProfile />} />
              <Route path="/general-settings" element={<GeneralSettings />} />
              <Route path="/order-tracking/:orderId" element={<OrderTracking />} />
              <Route path="/support" element={<Support />} />
              <Route path="/change-password" element={<ChangePassword />} />
            </Route>
          ) : (
            <Route path="/" element={<Navigate to="/login" />} />
          )}
          <Route path="/login" element={<Form setAuthState={setAuthState} />} />
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;
