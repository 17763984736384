import React from 'react';
import {
  HiOutlineViewGrid,
  HiOutlineCube,
  HiOutlineShoppingCart,
  HiOutlineUsers,
  HiOutlineDocumentText,
  HiOutlineAnnotation,
  HiOutlineQuestionMarkCircle,
  HiOutlineCog,
  HiOutlineInbox,
  HiUserCircle,
  HiOutlineUserGroup,
  HiOutlineUser,
  HiShoppingBag,
  HiLockClosed,
  HiShoppingCart,
  HiOutlineArchive,
  HiMap,
  HiOfficeBuilding
} from 'react-icons/hi';
import City from '../../components/City';


export const DASHBOARD_SIDEBAR_LINKS = [
  {
    key: 'dashboard',
    label: 'Dashboard',
    path: '/',
    icon: <HiOutlineViewGrid />
  },
  {
    key: 'manager',
    label: 'Manager',
    path: '/manager',
    icon: <HiOutlineUser />
  },
  {
    key: 'item',
    label: 'Item',
    path: '/item',
    icon: <HiOutlineInbox />
  },
  {
    key: 'inventory',
    label: 'Inventory',
    path: '/inventory',
    icon: <HiOutlineArchive />
  },
  {
    key: 'salesExecutive',
    label: 'Sales Executive',
    path: '/sales-executive',
    icon: <HiOutlineUsers />
  },
  {
    key: 'deliveryBoy',
    label: 'Delivery Boy',
    path: '/delivery-boy',
    icon: <HiOutlineUserGroup />
  },
  {
    key: 'order',
    label: 'Order',
    path: '/order',
    icon: <HiShoppingBag />
  },
  {
    key: 'shop',
    label: 'Shop',
    path: '/shop',
    icon: <HiShoppingCart />
  },
  {
    key: 'city',
    label: 'City',
    path: '/city',
    icon: <HiOfficeBuilding />
  },
  {
    key: 'changePassword',
    label: 'Change Password',
    path: '/change-password',
    icon: <HiLockClosed /> // Adding the lock icon for Change Password
  }
];


export const DASHBOARD_SIDEBAR_BOTTOM_LINKS = [
  // {
  //   key: 'settings',
  //   label: 'Settings',
  //   path: '/settings',
  //   icon: <HiOutlineCog />
  // },
  {
    key: 'support',
    label: 'Help & Support',
    path: '/support',
    icon: <HiOutlineQuestionMarkCircle />
  }
];
