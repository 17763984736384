// src/Loader.js
import React from 'react';

const Loader = () => {
  // Define the CSS styles
  const overlayStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    backgroundColor: 'rgba(0, 0, 0, 0.3)', // Semi-transparent gray background
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 9999, // Ensure the loader is above all other content
  };

  const spinnerStyle = {
    border: '8px solid rgba(0, 0, 0, 0.1)', // Light gray border
    borderRadius: '50%',
    borderTop: '8px solid #3498db', // Blue border for the spinning effect
    width: '50px',
    height: '50px',
    animation: 'spin 1s linear infinite',
  };

  // Inline CSS for keyframes animation
  const keyframes = `
    @keyframes spin {
      0% { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
    }
  `;

  // Create a style element for keyframes
  React.useEffect(() => {
    const styleSheet = document.createElement("style");
    styleSheet.type = "text/css";
    styleSheet.innerText = keyframes;
    document.head.appendChild(styleSheet);

    // Cleanup the style element on component unmount
    return () => {
      document.head.removeChild(styleSheet);
    };
  }, []);

  return (
    <div style={overlayStyle}>
      <div style={spinnerStyle}></div>
    </div>
  );
};

export default Loader;
